body {
    background-color: #c7ebea;
    height: 100vh;
    width: 100vw;
    margin: 0px 0 0 0;
    padding: 0px;
}

/*navbar*/
.navbar-nav {
    float: right;
    margin-right: 10%;
}

.active {
    color: #e6eaf2 !important;
    text-decoration: none;
    background-color: #0b9c8f;
    font-weight: bold;
    line-height: 1.5em;
}


#nav2:hover {
    background-color: #36b0b3;
    color: #f7f0f0 !important;
    text-decoration: none;
    line-height: 1.5em;
}
/*btn*/
.btn {
    color: black;
    text-decoration: none !important;
    border: none ;
}
.btn-link:hover {
    text-decoration: none;
    color: white;
    background-color: #36b0b3;
}
#btnDownload a {
    color: white;
    text-decoration: none;
    border: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

.container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
    max-width: 100%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-right: auto;
    margin-left: auto;
}
/*row in Home*/
.row {
    align-items: center;
    justify-content: center;
}
/*heading in Home menu*/
.masthead-heading {
    font-size: 1rem;
    line-height: .5rem;
    margin-top: 1rem;
}
.masthead-avatar {
    border: none;
}

.img-fluid {
    width: 100%;
    height: 100%;
}
img {
    max-width: 100%;
    display: block;
    height: auto;
    vertical-align: middle;
    border-style: none;
}
/*overlay for the images in the home menu*/
.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #008CBA;
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: .3s ease;
    transition: .3s ease;
}
.portfolio-item:hover .overlay {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}
.portfolio-item {
    cursor: pointer;
    overflow: hidden;
    border-radius: 12px;
    position: relative;
}

/*divider white line in home menu*/
.divider-custom {
    margin: 1.rem 0 1.5rem;
    width: 100%;
    top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.divider-custom-line {
    width: 100%;
    max-width: 6rem;
    height: 0.25rem;
    background-color: secondary;
    border-radius: 1rem;
    border-color: secondary !important;
    margin-right: 1rem;
    margin-left: 1rem;
}

.divider-custom-icon {
    color: secondary !important;
    font-size: 2rem;
    margin-top: .5rem;
}
.divider-light {
    /*background-color: ;*/
}
.divider-custom-line {
    background-color: white;
}
.divider-custom-icon {
    color: white !important;
}

/*Modal title and button*/
.portfolio-modal-title {
    font-size: 2.25rem;
    line-height: 2rem;
}
    @include media-breakpoint-up(lg) {
        font-size: 3rem;
        line-height: 2.5rem;
    }
    
    .close {
        position: absolute;
        z-index: 1;
        right: 1.5rem;
        top: 1rem;
        font-size: 3rem;
        line-height: 3rem;
        color: primary;
        opacity: 1;
    }
    

.modal-content {
    background-color: #c7ebea;
}
/*icon footer*/
.fa {
    color: #454241;
}
.fa-github{
   color: #383535;
}
.fa-linkedin {
    color: #383535;
}
.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}

/*github ribbon*/
#github-ribbon {
    position: absolute;
    right: 0;
}

/*middle menu*/

#home {
    margin-top: 10%;
    width: 80%;
    text-align: left;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);  
}

.Contact {
    width: 70%;
    text-align: left;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
Form {
    padding: 20px;
}
.masthead-heading1 {
    margin: 1.rem 0 1.5rem;
    width: 100%;
    top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*about.js*/
Card{
  top: 10;
  left: -0.1%;
}
.card{
    top: 50%;
    left: -0.1%;
    border-radius: 1rem;
    padding-left: 10px;
}
.about {
    color: black;
    background: #c7ebea;
    padding: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

/*loading content*/
.content{
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    background-color: #c7ebea;
    animation: bg 1.4s cubic-bezier(0, 0.2, 0.8, 1);
}

.loading {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 64px;
    height: 64px;
}
.loading div {
    position: absolute;
    background:  #c7ebea;
    opacity: 1;
    border-radius: 50%;
    animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
    animation-delay: -.7s;
}
@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

/* Header/Blog Title */
.blog {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.post-card {
    text-align: left;
    font-family: "Raleway";
    border: 1px solid lightblue;
    margin-bottom: 20px;
    padding: 0 20px;
}
.postlist {
    width: 40%;
    margin: auto;
    text-align: center;
}

.post {
    width: 40%;
    margin: auto;
}

.page-content {
    width: 40%;
    margin: auto;
    text-align: center;
}
#blog {
    background: #ebffff;
    margin-bottom: 10px;    
    overflow: scroll;
}

/* Create two unequal columns that floats next to each other */
/* Left column */
.leftcolumn {   
    float: left;
    width: 75%;
    border-radius: 90px;
}

/* Right column */
.rightcolumn {
    background: #d0f2e1;
    float: left;
    width: 25%;
    padding-left: 20px;
}

/* image */
.fakeimg {
    background-color: #aaa;
    width: 90%;
    padding: 20px;
    margin-bottom: 10px;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 800px) {
  .leftcolumn, .rightcolumn {   
    width: 100%;
    padding: 0;
  }
}

/*media-queries*/
@media (min-width: 320px) and (max-width: 480px) {
   
   .navbar {
     background-color:  rgba(255, 255, 255, 0.5);    
   }
   
   .footer {
         background-color: rgba(255, 255, 255, 0.5); 
   }
   .masthead-heading {
    font-size: 1rem;
    line-height: .5rem;
  }
  .masthead-avatar {
      width: 80%;
      height: 70%;
      margin-left: 9%;
  }
  #home {
    width: 100%; 
    height: 75%;
    
  }
   .portfolio-item {
       width: 40%;
       height: 40%;
   }
   .blog{
       margin-top: 30%;
        height: 80%;
        width: 100%;
    }
    .about{
        margin-top: -33%;
        height: 50%;
        width: 100%;
    }
    .Contact {
        text-align: left;
        max-width: 70%;
        margin: 10px 0px 10px 0px auto;
        padding-top: 40px;

    }
    .masthead-heading1 {
         font-size: 20px;
    }
    .card{
        top: 8%;
        left: 7%;
        width: 80%;
    }

}
@media (min-width: 481px) and (max-width: 767px){
  .masthead-heading {
    font-size: 1rem;
    line-height: .5rem;
  }

  #home {
    width: 90%;   
  }  


}
@media (min-width: 768px) and (max-width: 1024px) {
  .masthead-heading {
    font-size: 1.5rem;
    line-height: .5rem;
  }
  #home {
     width: 90%;
  }  
  
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .masthead-heading {
    font-size: 1.5rem;
    line-height: .5rem;
  }
  #home {
     width: 90%;
  }  
}
@media (min-width: 1025px) and (max-width: 1280px){
  .masthead-heading {
    font-size: 2rem;
    line-height: .5rem;
  }
  #home {
      width: 80%;
  }  
}
@media (min-width: 1281px) {
  .masthead-heading {
    font-size: 1.5rem;
    line-height: .5rem;
    display: inline;
  }
  #home {
        position: absolute;
        left: 50%;
        top: 30%;
        transform: translate(-50%, -50%);
        width: 45%;
        height: 55%;
        text-align: center;
    }  

}
